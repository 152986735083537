(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name persons.newPerson
   * @description
   *
   */
  angular
  .module('neo.home.persons.newPerson', [
    'ui.router'
  ]);
}());
